import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import { reset } from "./slice/reset";
import actionReducer from "./slice/actions";
import configsReducer from "./slice/configs";
import customModulesReducer from "./slice/custom-modules";
import dealsReducer from "./slice/deals";
import filesReducer from "./slice/files";
import notesReducer from "./slice/notes";
import enquiriesReducer from "./slice/enquiries";
import permissionsReducer from "./slice/permissions";
import primarySourcesReducer from "./slice/primary-sources";
import requestsReducer from "./slice/requests";
import sourcesReducer from "./slice/sources";
import stagesReducer from "./slice/stages";
import statusesReducer from "./slice/statuses";
import tasksReducer from "./slice/tasks";
import userGroupsReducer from "./slice/user-groups";
import userRolesReducer from "./slice/user-roles";
import useSettingReducer from "./slice/user-settings";
import usersReducer from "./slice/users";
import userReducer from "./slice/user";
import { leadReducer } from './slice/leads';
import { marketingReducer } from './slice/marketingInfo';
import { notificationReducer } from './slice/notification';


const appReducer: any = combineReducers({
  actions: actionReducer,
  configs: configsReducer,
  customModules: customModulesReducer,
  deals: dealsReducer,
  files: filesReducer,
  leads: leadReducer,
  notes: notesReducer,
  enquiries: enquiriesReducer,
  permissions: permissionsReducer,
  primarySources: primarySourcesReducer,
  requests: requestsReducer,
  sources: sourcesReducer,
  stages: stagesReducer,
  statuses: statusesReducer,
  tasks: tasksReducer,
  userGroups: userGroupsReducer,
  userRoles: userRolesReducer,
  users: usersReducer,
  userSettings: useSettingReducer, 
   marketing: marketingReducer,

  notification: notificationReducer,
  user: userReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === reset.type) {
    // Reset the entire state to initial state
    state = undefined;
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage: sessionStorage, // Use the custom storage here
  whitelist: [
    "leads",
    "actions",
    "users",
    "configs",
    "customModules",
    "primarySources",
    "requests",
    "sources",
    "stages",
    "statuses",
    "userGroups",
    "userRoles",
    "marketing",
    "userSettings"
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
        ignoredPaths: [],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

// Function to clear all data and perform logout
export const clearAllDataAndLogout = async (dispatch: AppDispatch) => {
  await persistor.purge();
  dispatch(reset());
  // Handle any additional logout logic here if necessary
};

export default store;
