import { AUTH_GET, AUTH_PUT } from "@grudder/app/actions-client";
import { config } from "@grudder/lib/config";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface FetchNotificationsParams {
    limit: number;
    skip: number;
}

export const fetchNotifications = createAsyncThunk(
    'notification/fetchNotifications',
    async ({ limit, skip}: FetchNotificationsParams) => {
        try {
            console.log("API Called")
            const response = await AUTH_GET(`${config.baseURL}/api/notification?limit=${limit}&skip=${skip}`, {

                revalidate: 3600,
                tags: ["notification", `notification-limit: ${limit}`, `notification-skip: ${skip}`],
            });
            console.log({ response })
            return response.data;
        } catch (error) {
            console.log({ error })
        }
    }
);

export const markNotificationAsRead = createAsyncThunk(
    'notification/markAsRead',
    async ({notificationId} : { notificationId: string; userId: string}) => {
        try {
            console.log(" Mark as Read API Called")
            const response = await AUTH_PUT(
                `${config.baseURL}/api/notification/mark-as-read/${notificationId}`,{
                    revalidate: 3600,
                    tags: ["notification"],
                }
            );
            console.log({ response })
            return response.data;
    } catch (error) {
        console.log({ error });
        throw error;
    }
}
);

export const markNotificationAsUnRead = createAsyncThunk(
    'notification/markAsUnread',
    async ({ notificationId }: {notificationId: string; userId: string}) => {
        try {
            console.log("Mark as Unread API Called")
            const response = await AUTH_PUT(
                `${config.baseURL}/api/notification/mark-as-unread/${notificationId}`, {
                    revalidate: 3600,
                    tags: ["notification"],
                }
            );
            console.log({ response })
            return response.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }
)
