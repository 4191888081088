import { getLeadDetails } from "@grudder/apiCalls";
import { AUTH_POST, AUTH_PUT } from "@grudder/app/actions-client";
import { config } from "@grudder/lib/config";
import { generateRandomId } from "@grudder/lib/utils";
import { handleDispatch } from "@grudder/providers/ReduxProvider";
import { LeadPayloadOptions } from "@grudder/types/leads.type";
import { createAsyncThunk } from "@reduxjs/toolkit";

export function buildLeadPayload(leadDetails: any, options: LeadPayloadOptions = {}) {
    const { customField, assignTo, status, stage, primarySource, source, campaign,customDataTypes } = options;
    return {
        fullName: leadDetails.fullName,
        email: leadDetails.email,
        phone: leadDetails.phone,
        ...(customField && { customDataTypes: customField }),
        ...(assignTo && { assignTo }),
        ...(status && { status }),
        ...(stage && { stage }),
        ...(primarySource && { primarySource: primarySource }),
        ...(source && { source: source }),
        ...(campaign && { campaign: campaign }),
        ...(customDataTypes && { customDataTypes: customDataTypes }),
    };
}

// Async thunk to assign a user to a lead
export const assignUserToLead = createAsyncThunk(
    "leads/assignUserToLead",
    async ({ leadId, leadDetails, assignTo }: { leadId: string, leadDetails: any, assignTo: string }, { rejectWithValue }) => {
        const randomId = generateRandomId();
        const name = `Assigning a new user to ${leadDetails.fullName}`;
        try {
            handleDispatch({
                type: "actions/addAction",
                payload: {
                    _id: randomId,
                    name: name,
                    type: "assignUserToLead",
                    status: "inprogress",
                },
            });
            const PAYLOAD = buildLeadPayload(leadDetails, { assignTo });
            const response = await AUTH_PUT(
                `${config.baseURL}/api/leads/${leadId}`,
                PAYLOAD
            );
            handleDispatch({
                type: "actions/updateAction",
                payload: {
                    _id: randomId,
                    status: "completed",
                },
            });
            return response;
        } catch (error) {
            handleDispatch({
                type: "actions/updateAction",
                payload: {
                    _id: randomId,
                    status: "failed",
                },
            });
            return rejectWithValue(error);
        } finally {
            //run this only after 10sec
            setTimeout(() => {
                handleDispatch({
                    type: "actions/removeAction",
                    payload: randomId,
                });
            }, 10000);
        }
    },
    {
        dispatchConditionRejection: true,
    }
);

// Async thunk to update a lead status
export const changeLeadStatus = createAsyncThunk(
    "leads/changeLeadStatus",
    async (
        {
            leadId,
            leadDetails,
            stage,
            status,
        }: { leadId: string; leadDetails: any; stage: string; status: string },
        { rejectWithValue }
    ) => {
        const randomId = generateRandomId();
        const name = `Changing the status of ${leadDetails.fullName}`;
        try {
            handleDispatch({
                type: "actions/addAction",
                payload: {
                    _id: randomId,
                    name: name,
                    type: "changeLeadStatus",
                    status: "inprogress",
                },
            });
            const PAYLOAD = buildLeadPayload(leadDetails, { stage, status });

            const response = await AUTH_PUT(
                `${config.baseURL}/api/leads/${leadId}`,
                PAYLOAD
            );
            return response;
        } catch (error) {
            handleDispatch({
                type: "actions/updateAction",
                payload: {
                    _id: randomId,
                    status: "failed",
                },
            });
            return rejectWithValue(error);
        } finally {
            //run this only after 10sec
            setTimeout(() => {
                handleDispatch({
                    type: "actions/removeAction",
                    payload: randomId,
                });
            }, 10000);
        }
    },
    {
        dispatchConditionRejection: true,
    }
);

// Async thunk to update custom fields
export const updateCustomFields = createAsyncThunk(
    "leads/updateCustomFields",
    async (
        {
            leadId,
            leadDetails,
            customField,
        }: { leadId: string; leadDetails: any; customField: any[] },
        { rejectWithValue }
    ) => {
        try {
            const PAYLOAD = buildLeadPayload(leadDetails, { customField });
            const response = await AUTH_PUT(
                `${config.baseURL}/api/leads/${leadId}`,
                PAYLOAD
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
    {
        dispatchConditionRejection: true,
    }
);

// Async thunk to edit a lead
export const editLead = createAsyncThunk(
    "leads/editLead",
    async (
        { leadId, leadDetails }: { leadId: string; leadDetails: any },
        { rejectWithValue }
    ) => {
        try {
            const PAYLOAD = buildLeadPayload(leadDetails);
            const response = await AUTH_PUT(
                `${config.baseURL}/api/leads/${leadId}`,
                PAYLOAD
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
    {
        dispatchConditionRejection: true,
    }
);

// Async thunk to fetch lead details
export const fetchLeadDetails = createAsyncThunk(
    "leads/fetchLeadDetails",
    async (leadId: string) => {
        const response = await getLeadDetails(leadId);
        return response;
    }
);

// Async thunk to create a new leadexport
export const createLead = createAsyncThunk(
    'leads/createLead',
    async (
        { leadDetails, }: { leadDetails: any },
        { rejectWithValue }
    ) => {
        try {
            const PAYLOAD = buildLeadPayload(leadDetails, {
                stage: leadDetails?.stage,
                status: leadDetails?.status,
                assignTo: leadDetails?.assignTo,
                primarySource: leadDetails?.primarySource,
                source: leadDetails?.source,
                campaign: leadDetails?.campaign,
                customDataTypes: leadDetails?.customDataTypes
            });
            const response = await AUTH_POST(`${config.baseURL}/api/leads`, PAYLOAD);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)
