import { LeadState } from "@grudder/types/leads.type";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "../reset";
import { assignUserToLead, changeLeadStatus, createLead, editLead, fetchLeadDetails, updateCustomFields } from "./leads.thunks";


const initialState: LeadState = {
    currentLeadIds: [],
    leads: [],
    total: 0,
    loading: false,
    listLoading: null,
    assignLoading: [],
    cdtEditLoading: [],
    editLoading: [],
    statusChangingLoading: [],
    error: null,
};

const leadSlice = createSlice({
    name: "leads",
    initialState,
    reducers: {
        setInitialLeads: (
            state,
            action: PayloadAction<{ data: any[]; total: number }>
        ) => {
            const leadMap = new Map(state.leads.map((lead) => [lead.id, lead]));
            action.payload.data.forEach((lead) => {
                // check if current is latest
                const currentLead = leadMap.get(lead.id);
                if (
                    currentLead &&
                    new Date(currentLead.updatedAt).getTime() >=
                    new Date(lead.updatedAt).getTime()
                ) {
                    return;
                }
                leadMap.set(lead.id, lead);
            });

            state.leads = Array.from(leadMap.values());
            state.currentLeadIds = action.payload.data.map((lead) => lead.id);
            state.total = action.payload.total;
        },
        updateLeadDetails: (state, action: PayloadAction<any | null>) => {
            // Create a Map from the current leads array for quick lookups
            const leadsMap = new Map(state.leads.map((lead) => [lead._id, lead]));

            const newLead = action.payload;
            const existingLead = leadsMap.get(newLead._id);

            // If the lead exists and the new lead data is new, update the Map
            if (existingLead) {
                if (
                    new Date(existingLead.updatedAt).getTime() <=
                    new Date(newLead.updatedAt).getTime()
                ) {
                    console.log(
                        "lead is New, updating the existing lead in the Map"
                    );
                    leadsMap.set(newLead._id, newLead);
                } else {
                    console.log("Existing lead is newer, keeping the old data");
                }
            } else {
                // If the lead does not exist, add it to the Map
                console.log("Lead does not exist, adding new lead to the Map");
                leadsMap.set(newLead._id, newLead);
            }

            // Convert the Map back to an array and update the state
            state.leads = Array.from(leadsMap.values());
        },
        setListLoading: (state, action: PayloadAction<string | null>) => {
            state.listLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLeadDetails.pending, (state, action) => {
                state.loading = true;
                const existingLead = state.leads.find(
                    (lead: any) => lead._id === action.meta.arg
                );
                if (existingLead) {
                    state.loading = false;
                }
            })
            .addCase(fetchLeadDetails.fulfilled, (state, action) => {
                // Create a Map from the current leads array for quick lookups
                const leadsMap = new Map(state.leads.map((lead) => [lead._id, lead]));

                const newLead = action.payload;
                const existingLead = leadsMap.get(newLead._id);

                // If the lead exists and the new lead data is new, update the Map
                if (existingLead) {
                    if (
                        new Date(existingLead.updatedAt).getTime() <=
                        new Date(newLead.updatedAt).getTime()
                    ) {
                        console.log(
                            "New lead is newer, updating the existing lead in the Map"
                        );
                        leadsMap.set(newLead._id, newLead);
                    } else {
                        console.log("Existing lead is newer, keeping the old data");
                    }
                } else {
                    // If the lead does not exist, add it to the Map
                    console.log("Lead does not exist, adding new lead to the Map");
                    leadsMap.set(newLead._id, newLead);
                }

                // Convert the Map back to an array and update the state
                state.leads = Array.from(leadsMap.values());
                state.loading = false;
            })
            .addCase(fetchLeadDetails.rejected, (state) => {
                state.loading = false;
            })
            .addCase(assignUserToLead.pending, (state, action) => {
                const leadId = action.meta.arg.leadId;
                state.assignLoading.push(leadId);
                state.error = null;
            })
            .addCase(assignUserToLead.fulfilled, (state, action) => {
                const leadId = action.meta.arg.leadId;
                const leadsMap = new Map(state.leads.map((lead) => [lead._id, lead]));
                leadsMap.set(action.payload._id, action.payload);
                state.assignLoading = state.assignLoading.filter(
                    (assignItem) => assignItem !== leadId
                );
                state.leads = Array.from(leadsMap.values());
            })
            .addCase(assignUserToLead.rejected, (state, action) => {
                const leadId = action.meta.arg.leadId;
                state.assignLoading = state.assignLoading.filter(
                    (assignItem) => assignItem !== leadId
                );
                state.error = action.payload;
            })
            .addCase(changeLeadStatus.pending, (state, action) => {
                const leadId = action.meta.arg.leadId;
                state.statusChangingLoading.push(leadId);
                state.error = null;
            })
            .addCase(changeLeadStatus.fulfilled, (state, action) => {
                const leadId = action.meta.arg.leadId;
                const leadsMap = new Map(state.leads.map((lead) => [lead._id, lead]));
                leadsMap.set(action.payload._id, action.payload);
                state.statusChangingLoading = state.statusChangingLoading.filter(
                    (statusItem) => statusItem !== leadId
                );
                state.leads = Array.from(leadsMap.values());

                // Update the action status to 'completed'
            })
            .addCase(changeLeadStatus.rejected, (state, action) => {
                const leadId = action.meta.arg.leadId;
                state.statusChangingLoading = state.statusChangingLoading.filter(
                    (statusItem) => statusItem !== leadId
                );
                state.error = action.payload;

                // Update the action status to 'failed'
            })
            .addCase(updateCustomFields.pending, (state, action) => {
                const leadId = action.meta.arg.leadId;
                state.cdtEditLoading.push(leadId);
                state.error = null;
            })
            .addCase(updateCustomFields.fulfilled, (state, action) => {
                const leadId = action.meta.arg.leadId;
                const leadsMap = new Map(state.leads.map((lead) => [lead._id, lead]));
                leadsMap.set(action.payload._id, action.payload);
                state.cdtEditLoading = state.cdtEditLoading.filter(
                    (cdtItem) => cdtItem !== leadId
                );
                state.leads = Array.from(leadsMap.values());
            })
            .addCase(updateCustomFields.rejected, (state, action) => {
                const leadId = action.meta.arg.leadId;
                state.cdtEditLoading = state.cdtEditLoading.filter(
                    (cdtItem) => cdtItem !== leadId
                );
                state.error = action.payload;
            })
            .addCase(editLead.pending, (state, action) => {
                const leadId = action.meta.arg.leadId;
                state.editLoading.push(leadId);
                state.error = null;
            })
            .addCase(editLead.fulfilled, (state, action) => {
                const leadId = action.meta.arg.leadId;
                const leadsMap = new Map(state.leads.map((lead) => [lead._id, lead]));
                leadsMap.set(action.payload._id, action.payload);
                state.editLoading = state.editLoading.filter(
                    (editItem) => editItem !== leadId
                );
                state.leads = Array.from(leadsMap.values());
            })
            .addCase(editLead.rejected, (state, action) => {
                const leadId = action.meta.arg.leadId;
                state.editLoading = state.editLoading.filter(
                    (editItem) => editItem !== leadId
                );
                state.error = action.payload;
            })
            //create lead cases
            .addCase(createLead.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createLead.fulfilled, (state, action) => {
                const leadsMap = new Map(state.leads.map((lead) => [lead._id, lead]));
                leadsMap.set(action.payload._id, action.payload);
                state.leads = Array.from(leadsMap.values());
                state.loading = false;
                state.currentLeadIds = [action.payload._id, ...state.currentLeadIds];
            })
            .addCase(createLead.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(reset, () => initialState); // Handle reset action
    },
});
export const { setInitialLeads, updateLeadDetails, setListLoading } =
    leadSlice.actions;

export default leadSlice.reducer;