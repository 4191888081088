import { MarketingState } from "@grudder/types/marketing.type";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "../reset";
import { fetchCampaigns, fetchCustomModules, fetchPrimarySources, fetchSources } from "./marketing.thunks";

const initialState: MarketingState = {
    primarySources: [],
    sources: [],
    campaigns: [],
    customModules: [],
    totalPrimarySources: 0,
    totalSources: 0,
    totalCampaigns: 0,
    loading: false,
    error: null,
};

const marketingSlice = createSlice({
    name: "marketing",
    initialState,
    reducers: {
        setPrimarySources: (state, action: PayloadAction<any[]>) => {
            state.primarySources = action.payload;
        },
        addPrimarySource: (state, action: PayloadAction<any>) => {
            state.primarySources = [...state.primarySources, action.payload];
        },
        updatePrimarySource: (state, action: PayloadAction<any>) => {
            const updatedPrimarySource = action.payload;
            const index = state.primarySources.findIndex(
                (primarySource) => primarySource._id === updatedPrimarySource._id
            );
            if (index !== -1) {
                state.primarySources[index] = updatedPrimarySource;
            }
        },
        setSources: (state, action: PayloadAction<any[]>) => {
            state.sources = action.payload;
        },
        addSource: (state, action: PayloadAction<any>) => {
            state.sources = [...state.sources, action.payload];
        },
        updateSource: (state, action: PayloadAction<any>) => {
            const updatedSource = action.payload;
            const index = state.sources.findIndex(
                (source) => source._id === updatedSource._id
            );
            if (index !== -1) {
                state.sources[index] = updatedSource;
            }
        },
        setCampaigns: (state, action: PayloadAction<any[]>) => {
            state.campaigns = action.payload;
        },
        addCampaign: (state, action: PayloadAction<any>) => {
            state.campaigns = [...state.campaigns, action.payload];
        },
        updateCampaign: (state, action: PayloadAction<any>) => {
            const updatedCampaign = action.payload;
            const index = state.campaigns.findIndex(
                (campaign) => campaign._id === updatedCampaign._id
            );
            if (index !== -1) {
                state.campaigns[index] = updatedCampaign;
            }
        },
        setCustomModules: (state, action: PayloadAction<any[]>) => {
            state.customModules = action.payload;
        },
        addCustomModule: (state, action: PayloadAction<any>) => {
            state.customModules = [...state.customModules, action.payload];
        },
        updateCustomModule: (state, action: PayloadAction<any>) => {
            const updatedCampaign = action.payload;
            const index = state.customModules.findIndex(
                (customModules) => customModules._id === updatedCampaign._id
            );
            if (index !== -1) {
                state.customModules[index] = updatedCampaign;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPrimarySources.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchPrimarySources.fulfilled, (state, action) => {
                state.primarySources = action.payload;
                state.loading = false;
            })
            .addCase(fetchPrimarySources.rejected, (state) => {
                state.loading = false;
            })
            .addCase(fetchSources.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchSources.fulfilled, (state, action) => {
                state.sources = action.payload;
                state.loading = false;
            })
            .addCase(fetchSources.rejected, (state) => {
                state.loading = false;
            })
            .addCase(fetchCampaigns.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCampaigns.fulfilled, (state, action) => {
                state.campaigns = action.payload;
                state.loading = false;
            })
            .addCase(fetchCampaigns.rejected, (state) => {
                state.loading = false;
            })
            .addCase(fetchCustomModules.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCustomModules.fulfilled, (state, action) => {
                state.customModules = action.payload;
                state.loading = false;
            })
            .addCase(fetchCustomModules.rejected, (state) => {
                state.loading = false;
            })
            .addCase(reset, () => initialState);
    }
});

export const {
    setPrimarySources,
    addPrimarySource,
    updatePrimarySource,
    setSources,
    addSource,
    updateSource,
    setCampaigns,
    addCampaign,
    updateCampaign,
    setCustomModules,
    addCustomModule,
    updateCustomModule
} = marketingSlice.actions;

export default marketingSlice.reducer;