import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchNotifications, markNotificationAsRead, markNotificationAsUnRead } from "./notification.thunks";

const initialState: any = {
    notifications: [],
    loading: false,
    error: null,
};


const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setNotifications: (state, action: PayloadAction<any[]>) => {
            state.notifications = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotifications.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                if (action.meta.arg.skip > 0) {
                    state.notifications = [...state.notifications, ...action.payload];
                } else {
                    state.notifications = action.payload;
                }
                state.loading = false;
            })
            .addCase(fetchNotifications.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(markNotificationAsRead.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(markNotificationAsRead.fulfilled, (state, action) => {
                state.loading = false;
                const { notificationId, userId } = action.meta.arg;
                const notification = state.notifications.find((n: any) => n._id === notificationId || n.id === notificationId);
                if (notification) {
                    const alreadyRead = notification.readUsers.some(
                        (readUser: any) => readUser.user === userId && readUser.read === true
                    );
                    if (!alreadyRead) {
                        notification.readUsers.push({ user: userId, read: true });
                    }
                }
                state.error = null;
            })
            .addCase(markNotificationAsRead.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(markNotificationAsUnRead.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(markNotificationAsUnRead.fulfilled, (state, action) => {
                state.loading = false;
                const { notificationId, userId} = action.meta.arg;

                const notification = state.notifications.find((n: any) => n._id === notificationId || n.id === notificationId);
                if (notification) {
                    notification.readUsers = notification.readUsers.filter(
                        (readUser: any) => !(readUser.user === userId && readUser.read)
                    );
                }
                state.error = null;
            })
            .addCase(markNotificationAsUnRead.pending, (state) => {
                state.loading = true;
                state.error = null;
            });
    }
});

export const { setNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;