import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";

interface UserState {
  user: any;
  loading: boolean;
  error: string | null;
}

// Define the initial state
const initialState: UserState = {
  user: null,
  loading: false,
  error: null,
};

// Define the slice
const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any[]>) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState); // Handle reset action
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
