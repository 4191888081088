import { AUTH_GET } from "@grudder/app/actions-client";
import { config } from "@grudder/lib/config";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchPrimarySources = createAsyncThunk(
  'marketing/fetchPrimarySources',
  async () => {
    try {
      console.log("API Called")
      const response = await AUTH_GET(`${config.baseURL}/api/lead-primary-source`, {
        revalidate: 3600,
        tags: ["primary-source"],
      });
      console.log({ response })
      return response.data;
    } catch (error) {
      console.log({ error })
    }
  }
);

export const fetchSources = createAsyncThunk(
  'marketing/fetchSources',
  async () => {
    const sourceResponse = await AUTH_GET(`${config.baseURL}/api/lead-source`, {
      revalidate: 3600,
      tags: ["source"],
    });
    console.log({ sourceResponse })
    return sourceResponse.data;
  }
)

export const fetchCampaigns = createAsyncThunk(
  'marketing/fetchCampaigns',
  async () => {
    const campaignResponse = await AUTH_GET(`${config.baseURL}/api/lead-campaign`, {
      revalidate: 3600,
      tags: ["campaign"],
    });
    console.log({ campaignResponse })
    return campaignResponse.data;
  }
)

export const fetchCustomModules = createAsyncThunk(
  'marketing/fetchCustomModules',
  async () => {
    const customModuleResponse = await AUTH_GET(`${config.baseURL}/api/custom-modules`, {
      revalidate: 3600,
      tags: ["custom-modules"],
    });
    console.log({ customModuleResponse })
    return customModuleResponse.data;
  }
)