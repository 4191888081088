import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reset } from "./reset";
import { getCustomModule } from "@grudder/apiCalls";
import { randomId } from "@mantine/hooks";

interface CustomModuleState {
  customModules: any[];
  loading: boolean;

  error: string | null;
  singleton: any | null;
}

// Define the initial state
const initialState: CustomModuleState = {
  customModules: [],
  loading: false,
  error: null,
  singleton: null,
};
const formatCustomModule = (customModule: any) => {
  return {
    formBuilding: false,
    isDraggable: false,
    _id: customModule?._id,
    moduleLabel: customModule.label,
    moduleName: customModule.name,
    moduleDescription: customModule.description ?? "",
    moduleReference: customModule.reference,
    fields: customModule?.customFields?.map((customField: any) => ({
      id: customField?._id,
      key: customField?.key ?? customField?._id,
      itemName: customField?.custom?.label,
      itemType: customField?.type,
      itemSystemKey: customField?.name,
      itemPlaceholder: customField?.custom?.placeholder,
      itemMaxLength: customField?.custom?.maxLength,
      itemRows: customField?.custom?.row,
      itemMinValue: customField?.custom?.minValue,
      itemMaxValue: customField?.custom?.maxValue,
      itemShowTime: customField?.custom?.showTime,
      itemIsMultiSelect: customField?.custom?.multiSelect,
      itemRequired: customField?.required,
      itemHidden: customField?.custom?.hidden,
      itemShowInFilter: customField?.isFilter,
      itemTrue: customField?.custom?.true,
      itemFalse: customField?.custom?.false,
      itemCondition: [],
      edit: false,
      deleteOnCancel: false,
      disableEdit: true,
      itemOptions: customField?.enum ?? [
        { label: "", value: "", key: randomId() },
      ],
      subfields: customField?.customDataTypeReferenceFields.map(
        (customField: any) => ({
          id: customField?._id,
          key: customField?.key ?? customField?._id,
          itemName: customField?.custom?.label,
          itemType: customField?.type,
          itemSystemKey: customField?.name,
          itemPlaceholder: customField?.custom?.placeholder,
          itemMaxLength: customField?.custom?.maxLength,
          itemRows: customField?.custom?.row,
          itemMinValue: customField?.custom?.minValue,
          itemMaxValue: customField?.custom?.maxValue,
          itemShowTime: customField?.custom?.showTime,
          itemIsMultiSelect: customField?.custom?.multiSelect,
          itemRequired: customField?.required,
          itemHidden: customField?.custom?.hidden,
          itemShowInFilter: customField?.isFilter,
          itemTrue: customField?.custom?.true,
          itemFalse: customField?.custom?.false,
          itemCondition: [],
          edit: false,
          deleteOnCancel: false,
          disableEdit: true,
          itemOptions: customField?.enum ?? [
            { label: "", value: "", key: randomId() },
          ],
        })
      ),
    })),
  };
};
// Define an async thunk to fetch user group details
export const fetchCustomModuleDetails = createAsyncThunk(
  "customModules/fetchCustomModuleDetails",
  async (moduleId: string, { getState, dispatch }) => {
    const state = getState() as { customModules: CustomModuleState };
    const customModules: any[] = state.customModules.customModules;
    // const singletonCustomModuleIsSame =
    //   state.customModules.singleton?._id === moduleId;
    // if (singletonCustomModuleIsSame) {
    //   return JSON.parse(JSON.stringify(state.customModules.singleton));
    // }

    const existingModule = customModules.find(
      (customModule: any) => customModule._id === moduleId
    );
    if (existingModule) {
      const payload = formatCustomModule(existingModule);
      dispatch(updateCustomModuleDetails(payload));
      return JSON.parse(JSON.stringify(payload));
    } else {
      const response = await getCustomModule(moduleId);
      const payload = formatCustomModule(response);
      return payload;
    }
  }
);

// Define the slice
const customModuleSlice = createSlice({
  name: "customModules",
  initialState,
  reducers: {
    setCustomModules: (state, action: PayloadAction<any[]>) => {
      const customModuleMap = new Map(
        state.customModules.map((customModule) => [
          customModule._id,
          customModule,
        ])
      );
      action.payload.forEach((customModule) => {
        const currentCustomModule = customModuleMap.get(customModule._id);
        if (
          currentCustomModule &&
          new Date(currentCustomModule.updatedAt).getTime() >=
            new Date(customModule.updatedAt).getTime()
        ) {
          return;
        }
        customModuleMap.set(customModule._id, customModule);
      });

      const updatedCustomModules = Array.from(customModuleMap.values());
      state.customModules = updatedCustomModules;
    },

    addCustomModule: (state, action: PayloadAction<any>) => {
      const currentPayload = action.payload;
      const index = state.customModules.findIndex(
        (customModule) => customModule._id === currentPayload._id
      );
      if (index !== -1) {
        state.customModules[index] = currentPayload;
      } else state.customModules = [...state.customModules, currentPayload];
    },
    removeCustomModule: (state, action: PayloadAction<any>) => {
      state.customModules = state.customModules.filter(
        (customModule) => customModule?._id !== action.payload
      );
    },
    updateCustomModule: (state, action: PayloadAction<any>) => {
      const updatedCustomModule = action.payload;
      const index = state.customModules.findIndex(
        (customModule) => customModule._id === updatedCustomModule._id
      );
      if (index !== -1) {
        state.customModules[index] = updatedCustomModule;
      }
    },
    updateCustomModuleDetails: (state, action: PayloadAction<any | null>) => {
      state.singleton = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchCustomModuleDetails.pending, (state) => {
        console.log("pending:here");
        state.loading = true;
      })
      .addCase(fetchCustomModuleDetails.fulfilled, (state, action) => {
        console.log("success:here");
        state.loading = false;
        state.singleton = action.payload;
      })
      .addCase(fetchCustomModuleDetails.rejected, (state) => {
        console.log("failed:here");
        state.loading = false;
      })

      .addCase(reset, () => initialState); // Handle reset action
  },
});

export const {
  setCustomModules,
  addCustomModule,
  updateCustomModule,
  removeCustomModule,
  updateCustomModuleDetails,
} = customModuleSlice.actions;

export default customModuleSlice.reducer;
