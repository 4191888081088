export * from './marketing.thunks';
export {
    setPrimarySources, 
    addPrimarySource,
    updatePrimarySource,
    setSources,
    addSource,
    updateSource,
    setCampaigns,
    addCampaign,
    updateCampaign,
    setCustomModules,
    addCustomModule,
    updateCustomModule,
    default as marketingReducer
} from './marketing.reducer';